
export const Server_URL = process.env.REACT_APP_SERVER_URL || "http://localhost:3000"

export const GAME_URL = "/Game/index.html";
export const feedBack_URL = "/api/feedback";
export const feedBackList_URL = "/api/feedback";
export const Log_URL = "/api/logs";
export const logList_URL = "/api/logs";
export const organisationList_URL = "/api/organisations";
export const scenarios_URL = "/api/scenarios";
export const scenario_URL = "/api/scenario";
export const Feedback_Images_Uploads_URL = "uploads";
export const Login_URL = "/api/login";
export const protected_resource_URL = "/api/protected-resource";
export const Info_URL = "/api/info";
export const Statistics_URL = "/api/statistics";
export const deleteScenario_URL = "/api/delete-scenario";
export const Role = {admin : "admin", user : "user",adminConsole: "adminConsole"};